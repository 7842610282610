import React from 'react'
import { graphql } from 'gatsby'
import { MDBRow, MDBCol, MDBContainer } from 'mdbreact'
import Layout from '../components/layout'
import Hero from '../components/heroSubpage'
import SEO from '../components/seo'

const ServicesTemplate = ({ data }) => {
  const post = data.markdownRemark
  return (
    <Layout>
      <div id="sub-page">
        <Hero
          class="intro-65 logo-overlay"
          gradientClass="mask d-flex justify-content-center align-items-center gradient"
          image={post?.frontmatter?.image?.childImageSharp?.gatsbyImageData}
          foreimage={post.frontmatter.foreimage.childImageSharp.gatsbyImageData}
          forealt={post.frontmatter.forealttext}
          title={post.frontmatter.title}
          subtitle={post.frontmatter.subtitle}
          ctatext={post.frontmatter.ctatext}
          ctaslug={post.frontmatter.ctaslug}             
          type="root"
        />

        <section className="bg-white">
          <MDBContainer>
            <MDBRow>
              <MDBCol lg={post.frontmatter.headline != null ? '8' : '12'}>
                <h3 className="font-w-400 text-xs-extra-large text-opening-para">
                  {post.frontmatter.openingpara}
                </h3>
                <div
                  className="richtext divlink mt-3"
                  dangerouslySetInnerHTML={{ __html: post.html }}
                />
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </section>

        {post.frontmatter.quote &&
        <section className="blockback">
          <MDBContainer>
            <div className="blockquote-wrapper">
              <div className="blockquote">
                <h2>{post.frontmatter.quote[0].strapline}</h2>
                <h3 style={{ whiteSpace: 'pre-wrap' }}>
                  {post.frontmatter.quote[0].author}
                </h3>
              </div>
            </div>
          </MDBContainer>
        </section>
        }
      </div>
    </Layout>
  )
}
export default ServicesTemplate

export function Head({ data, location }) {
  const post = data.markdownRemark
  return (
    <SEO
      title={post.frontmatter.meta.title}
      description={post.frontmatter.meta.description}
      url={location.pathname} 
      image={post.frontmatter.image.childImageSharp?.gatsbyImageData?.images?.fallback?.src}          
      canonical={location.pathname}                  
    />
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(
      fields: { slug: { eq: $slug } }
      frontmatter: { template: { eq: "services" } }
    ) {
      html
      frontmatter {
        template
        meta {
          title
          description 
        }
        title
        subtitle
        alttext
        ctatext
        ctaslug     
        image {
          childImageSharp {
            gatsbyImageData(quality: 90) 
          }
        }
        forealttext
        foreimage {
          childImageSharp {
            gatsbyImageData(quality: 90) 
          }
        }
        quote {
          strapline
          author
        }
        openingpara
      }
    }
  }
`